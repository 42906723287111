<template>
  <div
    id="countdown-container"
    class="flex items-center"
    :class="[displayColorClass, typographyClass]"
  >
    <VIcon v-if="showIcon" icon="timer" class="mr-1 material-icons-outlined" size="16" />
    <span data-testid="display-time"> {{ formattedTime }} </span>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import type { UrgencyWithCountdown } from '@/util/UrgencyWithCountdown'
import { intervalToDuration, parseISO } from 'date-fns'

const { t } = useI18n()

const props = withDefaults(
  defineProps<{
    urgency: UrgencyWithCountdown
    nextEscalationAt: string
    showIcon?: boolean
    dark?: boolean
    regular?: boolean
  }>(),
  { dark: false, regular: false, showIcon: true }
)

const endTime = computed<Date>(() => parseISO(props.nextEscalationAt))

const intervalId = ref<any>(undefined)
const remainingDuration = ref(intervalToDuration({ start: new Date(), end: endTime.value }))

const formattedTime = computed<string>(() => {
  const { hours = 0, minutes = 0, seconds = 0 } = remainingDuration.value

  if (hours <= 0 && minutes <= 0 && seconds <= 0) {
    return `0 ${t('sec.')}`
  }

  if (hours >= 1) {
    return `${hours} ${t('h')}`
  }
  return minutes < 1 ? `${seconds} ${t('sec.')}` : `${minutes} ${t('min.')} ${seconds} ${t('sec.')}`
})

const colorClassByUrgencyType: Record<UrgencyWithCountdown, string> = {
  emergency: 'text-error',
  urgent: props.dark ? 'text-warning-dark' : 'text-warning',
  routine: 'text-secondary-text'
}

const displayColorClass = computed<string>(() => colorClassByUrgencyType[props.urgency])

const typographyClass = computed<string>(() => {
  return props.regular ? '' : 'countdown-typography'
})

onMounted(() => {
  calculateRemainingDuration()
  intervalId.value = setInterval(() => {
    calculateRemainingDuration()
  }, 1000)
})

function calculateRemainingDuration(): void {
  if (endTime.value > new Date()) {
    remainingDuration.value = intervalToDuration({ start: new Date(), end: endTime.value })
  } else {
    clearInterval(intervalId.value)
  }
}
</script>

<style scoped>
.countdown-typography {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
  letter-spacing: 0.15px;
}
</style>
