<template>
  <VAutocomplete
    v-model="selected"
    v-model:search="searchInput"
    :items="searchItems"
    menu-icon=""
    variant="solo-filled"
    prepend-inner-icon="search"
    :placeholder="t('Search for patients...')"
    flat
    hide-details
    @update:model-value="onSelect"
  >
    <template #item="itemArgs">
      <PatientSearchItem
        :item-props="itemArgs.props"
        :display-data="itemArgs.item.raw.displayData"
        :search-input="searchInput"
      />
    </template>
  </VAutocomplete>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { useCaseStore } from '@/stores/case'
import { computed, ref } from 'vue'
import type { CaseDTO } from '@/generated'
import { useRouter } from 'vue-router'
import { routeNames } from '@/router/routes'
import type { SearchItem } from '@/components/navigation/patientsearch/SearchItem'
import PatientSearchItem from '@/components/navigation/patientsearch/PatientSearchItem.vue'
import { format, parseISO } from 'date-fns'

const { t } = useI18n()
const router = useRouter()
const { caseList } = storeToRefs(useCaseStore())

const selected = ref<string | undefined>()

const searchInput = ref<string>('')

const searchItems = computed<SearchItem<string>[]>(() =>
  caseList.value.map((caze: CaseDTO) => ({
    value: caze.id,
    title: `${caze.externalId}, ${caze.patient.lastName}, ${caze.patient.firstName}, ${format(parseISO(caze.patient.dateOfBirth), 'dd.MM.yyyy')}, ${caze.hospital.name}, ${caze.ward.name}`,
    displayData: {
      id: caze.externalId,
      name: `${caze.patient.lastName}, ${caze.patient.firstName}`,
      dateOfBirth: format(parseISO(caze.patient.dateOfBirth), 'dd.MM.yyyy'),
      hospital: caze.hospital.name,
      ward: caze.ward.name
    }
  }))
)

function onSelect() {
  if (!selected.value) return

  const caseId = selected.value
  selected.value = undefined
  router.push({ name: routeNames.PATIENT_DETAILS, params: { caseId } })
}
</script>
