<template>
  <VContainer class="p-0">
    <VRadioGroup v-model="selectedShiftType" :disabled="hasGodModeShift" class="mb-4" hide-details>
      <VRadio
        v-for="item in availableShiftTypes"
        :key="`shift-radio-button-${item}`"
        :label="translateShiftTypeDTO(item, t)"
        :value="item"
        :data-testid="item"
        :ripple="false"
        base-color="secondary-text"
      />

      <VRadio
        v-for="item in availableUserRights"
        :key="`shift-radio-button-${item}`"
        :label="translateUserRightsEnum(item, t)"
        :value="item"
        :data-testid="item"
        :ripple="false"
        base-color="secondary-text"
      />
    </VRadioGroup>
    <THErrorCard v-if="showPendingReportsErrorCard" class="mb-4">
      {{ t('Please complete all pending reports so that you can change to no shift.') }}
    </THErrorCard>
    <THErrorCard v-else-if="showOutgoingRequestsErrorCard" class="mb-4">
      {{
        t(
          'You have outstanding outgoing consultations. Please run a handover in order to change your shift.'
        )
      }}
    </THErrorCard>
    <VBtn
      :disabled="isButtonDisabled || hasGodModeShift"
      :class="buttonClass"
      color="primary"
      :size="props.buttonSize"
      @click="onSave()"
      data-testid="shift-btn-submit"
    >
      {{ t(props.buttonLabel) }}
    </VBtn>
  </VContainer>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { type ShiftDTO, ShiftTypeDTO, UserRightsEnum } from '@/generated'
import { ShiftService } from '@/services/telehealthApi/ShiftService'
import { useUserStore } from '@/stores/user'
import { NoticeType } from '@/stores/models/Notice'
import { useNoticeStore } from '@/stores/notice'
import THErrorCard from '@/atoms/THErrorCard.vue'
import { storeToRefs } from 'pinia'
import { useConsultationRequestStore } from '@/stores/consultationRequest'
import { translateShiftTypeDTO, translateUserRightsEnum } from '@/util/translations'
import { useRouter } from 'vue-router'
import { routeNames } from '@/router/routes'

const props = withDefaults(
  defineProps<{
    buttonLabel?: string
    buttonSize?: 'small' | 'medium' | 'large'
    showSuccessMessage?: boolean
  }>(),
  {
    buttonLabel: 'Continue',
    buttonSize: 'large',
    showSuccessMessage: false
  }
)

const { t } = useI18n()
const noticeStore = useNoticeStore()
const { user, hasSelectedShift, hasGodModeShift } = storeToRefs(useUserStore())
const { setCurrentShift, setCurrentRole, hasDoctorRole } = useUserStore()
const router = useRouter()

const { areReportsPending, areOutgoingRequestsPending } = storeToRefs(useConsultationRequestStore())

const selectedShiftType = ref<ShiftTypeDTO | UserRightsEnum | undefined>(user.value?.currentShift)
const buttonClass = `button-${props.buttonSize}`

onMounted(() => {
  const userRights = user.value?.userRights
  if (userRights?.length === 1 && userRights?.includes(UserRightsEnum.HOSPITAL_IT)) {
    setCurrentRole(UserRightsEnum.HOSPITAL_IT)
    setCurrentShift(undefined)
    router.push({ name: routeNames.HOSPITAL_ADMIN })
  }
})

const availableShiftTypes = computed<ShiftTypeDTO[]>(() => {
  if (!hasDoctorRole) {
    return []
  }

  return Object.values(ShiftTypeDTO).filter((shiftType) => {
    if (shiftType === ShiftTypeDTO.GOD_MODE) {
      return user.value?.userRights.includes(UserRightsEnum.GOD_MODE)
    }
    if (shiftType === ShiftTypeDTO.CHIEF_PHYSICIAN) {
      return user.value?.userRights.includes(UserRightsEnum.CHIEF_PHYSICIAN)
    }

    return true
  })
})

const availableUserRights = computed<UserRightsEnum[]>(() => {
  const excludedRights = [
    UserRightsEnum.GOD_MODE,
    UserRightsEnum.CHIEF_PHYSICIAN,
    UserRightsEnum.HOSPITAL_PHYSICIAN
  ] as UserRightsEnum[]

  return (
    user.value?.userRights.filter((userRight) => {
      return !excludedRights.includes(userRight)
    }) || []
  )
})

const showPendingReportsErrorCard = computed<boolean>(
  () => areReportsPending.value && selectedShiftType.value === ShiftTypeDTO.NO_SHIFT
)

const showOutgoingRequestsErrorCard = computed<boolean>(
  () =>
    hasSelectedShift &&
    selectedShiftType.value === ShiftTypeDTO.NO_SHIFT &&
    areOutgoingRequestsPending.value
)

const isButtonDisabled = computed<boolean>(
  () =>
    !selectedShiftType.value ||
    showPendingReportsErrorCard.value ||
    showOutgoingRequestsErrorCard.value
)

async function onSave(): Promise<void> {
  const shiftType = selectedShiftType.value!

  if (shiftType === UserRightsEnum.HOSPITAL_IT) {
    setCurrentRole(UserRightsEnum.HOSPITAL_IT)
    setCurrentShift(undefined)
    router.push({ name: routeNames.HOSPITAL_ADMIN })
    return
  }

  const shiftDTO: ShiftDTO = {
    userName: user.value!.userName,
    type: shiftType as ShiftTypeDTO
  }

  try {
    await ShiftService.createAndBeginShift(shiftDTO)
    setCurrentShift(shiftType as ShiftTypeDTO)
    setCurrentRole(undefined)
    if (props.showSuccessMessage) {
      noticeStore.add({
        type: NoticeType.SUCCESS,
        message: 'You changed your shift'
      })
    }
  } catch (error) {
    console.error(error)
    noticeStore.add({
      type: NoticeType.ERROR,
      message: 'Failed to save data'
    })
  }
}
</script>
