import ConsultationsView from '@/views/ConsultationsView.vue'
import type { RouteRecordRaw } from 'vue-router'
import {
  hasGodModeShiftAndNotHospitalAdmin,
  hasUserHospitalItRole,
  restrictToHospitalAdmin
} from './routerguards'
import VitalSignsView from '@/views/VitalSignsView.vue'
import VitalDataSettingsView from '@/views/VitalDataSettingsView.vue'

export const routeNames = {
  CONSULTATIONS: 'consultations',
  JOIN_CONSULTATION: 'join-consultation',
  PATIENT_DETAILS: 'patient-details',
  PATIENT_DETAILS_LIVE_DATA: 'patient-details-live-data',
  USER_PROFILE: 'user-profile',
  CREATE_CONSULTATION: 'create-consultation-request',
  LOGOUT: 'logout',
  LOGOUT_HANDOVER: 'logout-handover',
  WRITE_REPORT: 'write-report',
  NOTIFICATION_CENTER: 'notification-center',
  VITAL_DATA: 'vital-data',
  VITAL_DATA_SETTINGS: 'vital-data-settings',
  HOSPITAL_ADMIN: 'hospital-admin'
} as const
export type routeNames = (typeof routeNames)[keyof typeof routeNames]

export const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: routeNames.CONSULTATIONS,
    component: ConsultationsView,
    beforeEnter: restrictToHospitalAdmin
  },
  {
    path: '/vital-data',
    name: routeNames.VITAL_DATA,
    component: VitalSignsView,
    beforeEnter: restrictToHospitalAdmin
  },
  {
    path: '/vital-data-settings',
    name: routeNames.VITAL_DATA_SETTINGS,
    component: VitalDataSettingsView,
    beforeEnter: restrictToHospitalAdmin
  },
  {
    path: '/:consultationShareCode',
    name: routeNames.JOIN_CONSULTATION,
    component: () => import('../components/CallFrame.vue'),
    props: true,
    beforeEnter: restrictToHospitalAdmin
  },
  {
    path: '/patient-details/:caseId',
    name: routeNames.PATIENT_DETAILS,
    component: () => import('../views/PatientDetailView.vue'),
    props: true,
    beforeEnter: restrictToHospitalAdmin
  },
  {
    path: '/patient-details-live-data/:caseId',
    name: routeNames.PATIENT_DETAILS_LIVE_DATA,
    component: () => import('../views/PatientDetailLiveDataView.vue'),
    props: true,
    beforeEnter: restrictToHospitalAdmin
  },
  {
    path: '/user-profile',
    name: routeNames.USER_PROFILE,
    component: () => import('../views/UserProfileView.vue'),
    beforeEnter: restrictToHospitalAdmin
  },
  {
    path: '/create-consultation-request',
    name: routeNames.CREATE_CONSULTATION,
    component: () => import('../views/CreateConsultationView.vue'),
    beforeEnter: hasGodModeShiftAndNotHospitalAdmin
  },
  {
    path: '/logout',
    name: routeNames.LOGOUT,
    component: () => import('../views/LogoutView.vue')
  },
  {
    path: '/logout-handover',
    name: routeNames.LOGOUT_HANDOVER,
    component: () => import('../views/LogoutHandoverView.vue'),
    beforeEnter: hasGodModeShiftAndNotHospitalAdmin
  },
  {
    path: '/report/:consultationRequestId',
    name: routeNames.WRITE_REPORT,
    component: () => import('../views/ConsultationReportView.vue'),
    props: true,
    beforeEnter: hasGodModeShiftAndNotHospitalAdmin
  },
  {
    path: '/notification-center',
    name: routeNames.NOTIFICATION_CENTER,
    component: () => import('../views/NotificationCenterView.vue'),
    beforeEnter: hasGodModeShiftAndNotHospitalAdmin
  },
  {
    path: '/admin',
    name: routeNames.HOSPITAL_ADMIN,
    component: () => import('../views/AdminView.vue'),
    beforeEnter: hasUserHospitalItRole
  }
]
