<template>
  <span data-testid="patient-title">{{ displayTitle }}</span>
</template>

<script setup lang="ts">
import { type PatientDTO } from '@/generated'
import { computed } from 'vue'
import { translateGenderDTO } from '@/util/translations'
import { useI18n } from 'vue-i18n'
import { differenceInYears, parseISO } from 'date-fns'
import { UTCDate } from '@date-fns/utc'

const { t } = useI18n()
const props = defineProps<{
  patient: PatientDTO
}>()

const dateOfBirth = computed<Date>(() => new UTCDate(parseISO(props.patient.dateOfBirth)))

const age = computed<number>(() => Math.trunc(differenceInYears(new Date(), dateOfBirth.value)))
const displayTitle = computed<string>(
  () =>
    `${props.patient.lastName}, ${props.patient.firstName} (${translateGenderDTO(props.patient.gender, t)}, ${age.value})`
)
</script>
