import { type ConsultationRequestDTO, UrgencyDTO } from '@/generated'
import { parseISO, differenceInMilliseconds } from 'date-fns'
import type { ConsultationRequestActivity } from '@/components/patient/models/ConsultationRequestActivity'

export function compareConsultationRequests(
  a: ConsultationRequestDTO,
  b: ConsultationRequestDTO
): number {
  const urgencyOrder = compareUrgency(a.urgency, b.urgency)
  if (urgencyOrder !== 0) {
    return urgencyOrder
  }
  return compareNextEscalationAt(a.nextEscalationAt, b.nextEscalationAt)
}

const urgencyPriority: Partial<Record<UrgencyDTO, number>> = {
  [UrgencyDTO.EMERGENCY]: 0,
  [UrgencyDTO.URGENT]: -1,
  [UrgencyDTO.ROUTINE]: -2
}
const lowestPriority: number = Math.min(...Object.values(urgencyPriority)) - 1

function compareUrgency(a: UrgencyDTO, b: UrgencyDTO): number {
  const aPriority = urgencyPriority[a] ?? lowestPriority
  const bPriority = urgencyPriority[b] ?? lowestPriority
  return bPriority - aPriority
}

function compareNextEscalationAt(a: string | undefined, b: string | undefined): number {
  if (a === b) return 0
  if (!a) return 1
  if (!b) return -1
  const aDate = parseISO(a)
  const bDate = parseISO(b)
  return differenceInMilliseconds(aDate, bDate)
}

export function compareConsultationRequestActivities(
  a: ConsultationRequestActivity,
  b: ConsultationRequestActivity
): number {
  return differenceInMilliseconds(b.timeStamp, a.timeStamp)
}
