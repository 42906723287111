<template>
  <VDialog
    v-model="show"
    max-width="600px"
    :transition="false"
    attach="#app"
    data-testid="emergency-consultation-dialog"
  >
    <VCard class="p-4">
      <VCardTitle class="flex justify-space-between items-center p-0">
        <div class="text-h4 p-4">
          {{ t('Emergency') }}
        </div>
        <VBtn
          icon="close"
          variant="plain"
          class="button-small mr-2"
          size="small"
          :ripple="false"
          data-testid="btn-close"
          @click="close"
        />
      </VCardTitle>
      <VCardText class="p-4">
        <div class="text-subtitle-1">{{ t('Patient') }}</div>
        <PatientAutocomplete
          v-model="selectedCaseId"
          only-available-emergencies
          class="pb-12"
          data-testid="patient-autocomplete"
        />
      </VCardText>
      <template #actions>
        <VCardActions>
          <VBtn
            variant="text"
            color="primary"
            size="large"
            data-testid="btn-cancel"
            class="ml-auto button-large"
            :text="t('Cancel')"
            @click="close"
          />
          <VBtn
            variant="elevated"
            color="primary"
            size="large"
            class="ml-4 button-large"
            :text="t('Start emergency call')"
            data-testid="btn-confirm"
            :loading="createEmergencyInProgress"
            :disabled="!selectedCaseId"
            @click="confirmAndClose"
          />
        </VCardActions>
      </template>
    </VCard>
  </VDialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ref, watch } from 'vue'
import PatientAutocomplete from '@/components/patient/components/atoms/PatientAutocomplete.vue'
import { ConsultationRequestService } from '@/services/telehealthApi/ConsultationRequestService'
import { NoticeType } from '@/stores/models/Notice'
import { useNoticeStore } from '@/stores/notice'
import { useCallStore } from '@/stores/call'

const show = defineModel<boolean>({ required: true })

const selectedCaseId = ref<string | undefined>()
const createEmergencyInProgress = ref<boolean>(false)

const { t } = useI18n()
const noticeStore = useNoticeStore()

async function confirmAndClose() {
  try {
    createEmergencyInProgress.value = true
    const createdRequestId = await ConsultationRequestService.createEmergencyRequest(
      selectedCaseId.value!
    )
    await useCallStore().joinCall(createdRequestId)
    close()
  } catch (error) {
    console.error((error as Error).message)
    noticeStore.add({
      type: NoticeType.ERROR,
      message: 'Failed to submit consultation request'
    })
  } finally {
    createEmergencyInProgress.value = false
  }
}

function close() {
  show.value = false
}

function clear() {
  selectedCaseId.value = undefined
}

watch(show, () => {
  if (!show.value) clear()
})
</script>
