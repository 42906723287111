import {
  type GetAssignedHospitalsResponse,
  type GetUserSettingsResponse,
  UserRightsEnum,
  UserSettingsApi,
  type UserSettingsUpdateDTO
} from '@/generated'
import { globalApiConfig } from '@/services/telehealthApi/APIConfig'
import { changeLanguage, languages, validateLanguageCode } from '@/i18n/i18n'
import { useUserStore } from '@/stores/user'
import { urlSafeBase64 } from '@/util/base64'

export class UserSettingsService {
  static readonly userSettingsApi = new UserSettingsApi(globalApiConfig)

  static async updateUserSettings(
    userName: string,
    userSettingsUpdateDTO: UserSettingsUpdateDTO
  ): Promise<void> {
    const encodedUserName = urlSafeBase64(userName)
    await UserSettingsService.userSettingsApi.patchUserSettings(
      encodedUserName,
      userSettingsUpdateDTO
    )
  }

  static async getUserSettings(userName: string): Promise<GetUserSettingsResponse> {
    const encodedUserName = urlSafeBase64(userName)
    return (await UserSettingsService.userSettingsApi.getUserSettings(encodedUserName)).data
  }

  static async getAssignedHospitals(userName: string): Promise<GetAssignedHospitalsResponse> {
    const encodedUserName = urlSafeBase64(userName)

    return (await UserSettingsService.userSettingsApi.getAssignedHospitals(encodedUserName)).data
  }

  static async syncUserWithBackend(): Promise<void> {
    const userStore = useUserStore()
    const userName = userStore.user!.userName

    try {
      const response = await UserSettingsService.getUserSettings(userName)

      const language = response.userSettings.language ?? languages.ENGLISH
      const notificationSettings = response.systemSettings.notification
      const userRights = response.userSettings.userRights || []

      validateLanguageCode(language)
      changeLanguage(language as languages)

      if (userRights.length === 1 && userRights[0] === UserRightsEnum.HOSPITAL_IT) {
        userStore.setCurrentRole(UserRightsEnum.HOSPITAL_IT)
      }

      userStore.setCurrentShift(response.userSettings.shift?.type)
      userStore.setLanguage(language as languages)
      userStore.setMedicalFields(response.userSettings.medicalFields)
      userStore.setFirebaseOptions({
        apiKey: notificationSettings.apiKey,
        appId: notificationSettings.appId,
        authDomain: notificationSettings.authDomain,
        projectId: notificationSettings.projectId,
        messagingSenderId: notificationSettings.messagingSenderId,
        vapidKey: notificationSettings.vapidKey
      })
      userStore.setUserRights(userRights)
    } catch (error) {
      console.error(error)
      //TODO: show permanent error in system bar
    }
  }

  static async loadAssignedHospitalsAndWards(): Promise<void> {
    const userStore = useUserStore()
    const userName = userStore.user!.userName

    try {
      const response = await UserSettingsService.getAssignedHospitals(userName)
      userStore.setWards(response.wards)
      userStore.setHospitals(response.hospitals)
    } catch (error) {
      console.error(error)
      //TODO: show permanent error in system bar
    }
  }
}
