/* c8 ignore file */
import { ShiftTypeDTO, UserRightsEnum } from '@/generated'
import { AuthenticationService } from '@/services/AuthenticationService'
import { useUserStore } from '@/stores/user'
import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'
import { routeNames } from './routes'

export async function globalRouterGuards() {
  await AuthenticationService.updateAccessToken()
}

export function hasUserGodModeShift(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const userStore = useUserStore()

  if (userStore.user?.currentShift === ShiftTypeDTO.GOD_MODE) {
    next('/')
  } else {
    next()
  }
}

export function hasUserChiefPhysicianShift(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const userStore = useUserStore()

  if (userStore.user?.currentShift !== ShiftTypeDTO.CHIEF_PHYSICIAN) {
    next('/')
  } else {
    next()
  }
}

export function hasUserHospitalItRole(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const userStore = useUserStore()

  if (userStore.user?.currentRole !== UserRightsEnum.HOSPITAL_IT) {
    next('/')
  } else {
    next()
  }
}

export function restrictToHospitalAdmin(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const userStore = useUserStore()

  if (
    userStore.user?.currentRole === UserRightsEnum.HOSPITAL_IT &&
    to.name !== routeNames.HOSPITAL_ADMIN
  ) {
    next({ name: routeNames.HOSPITAL_ADMIN })
  } else {
    next()
  }
}

export function hasGodModeShiftAndNotHospitalAdmin(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const userStore = useUserStore()

  if (
    userStore.user?.currentRole === UserRightsEnum.HOSPITAL_IT &&
    to.name !== routeNames.HOSPITAL_ADMIN
  ) {
    next({ name: routeNames.HOSPITAL_ADMIN })
  } else if (userStore.user?.currentShift === ShiftTypeDTO.GOD_MODE) {
    next('/')
  } else {
    next()
  }
}
