<template>
  <VAutocomplete
    v-model="selectedId"
    :items="autocompleteItems"
    :rules="[requiredRule]"
    :placeholder="t('Search...')"
    :loading="isLoading"
    :disabled="isLoading"
    class="mb-1"
  >
    <template #item="autocompleteItem">
      <VListItem
        v-bind="autocompleteItem.props"
        :title="autocompleteItem.item.title"
        :disabled="autocompleteItem.item.raw.disabled"
        data-testid="patient-autocomplete-options"
      />
    </template>
  </VAutocomplete>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { requiredRule } from '@/util/validation/validationRules'
import { storeToRefs } from 'pinia'
import { useCaseStore } from '@/stores/case'
import { computed, onMounted } from 'vue'
import type { SelectOption } from '@/util/SelectOption'
import type { CaseDTO } from '@/generated'
import { format, parseISO } from 'date-fns'

const props = withDefaults(
  defineProps<{
    onlyAvailableEmergencies?: boolean
  }>(),
  { onlyAvailableEmergencies: false }
)

const caseStore = useCaseStore()

const selectedId = defineModel<string>()
const { t } = useI18n()
const { caseList } = storeToRefs(useCaseStore())

const autocompleteItems = computed<SelectOption<string>[]>(() =>
  caseList.value.map((caze: CaseDTO) => ({
    value: caze.id,
    title: `${caze.externalId}, ${caze.patient.lastName}, ${caze.patient.firstName}, ${format(parseISO(caze.patient.dateOfBirth), 'dd.MM.yyyy')}`,
    disabled: props.onlyAvailableEmergencies && caze.emergencyUnavailable
  }))
)

const isLoading = computed<boolean>(() => caseList.value.length === 0)

onMounted(caseStore.refreshCases)
onMounted(caseStore.getVitalSigns)
</script>
