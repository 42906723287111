<template>
  <VContainer fluid class="m-0 pl-4 pr-4 lg:!pl-36 lg:!pr-36 w-full">
    <VRow align="center" class="flex-nowrap gap-2 lg:gap-4" no-gutters>
      <VCol class="flex-grow-0">
        <VImg
          :width="35"
          :height="40"
          :src="logo"
          alt="Telehealth Logo"
          draggable="false"
          class="cursor-pointer"
          @click="routeToConsultations"
        />
      </VCol>
      <VCol class="flex-grow-0 text-h5 text-nowrap">{{ t('e:lmo User Administration') }}</VCol>

      <VSpacer />

      <VCol class="flex-grow-0">
        <VBtn
          @click="logout"
          variant="text"
          :active="false"
          class="button-medium text-h5 w-full justify-start text-primary"
          data-testid="logout-button"
        >
          {{ t('Logout') }}
        </VBtn>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import logo from '@/assets/tcclogo.png'
import { routeNames } from '@/router/routes'
import { useRouter } from 'vue-router'
import { useRepeatWhileMounted } from '@/composables/repeatWhileMounted'
import { useNotificationStore } from '@/stores/notification'
import { getRefreshIntervalNotificationCenter } from '@/global/environmentGetters'
import { PushNotificationService } from '@/services/PushNotificationService'
import { AuthenticationService } from '@/services/AuthenticationService'

const { t } = useI18n()
const router = useRouter()

useRepeatWhileMounted(
  useNotificationStore().refreshNotificationList,
  getRefreshIntervalNotificationCenter()
)

async function routeToConsultations() {
  await router.push({ name: routeNames.CONSULTATIONS })
}

async function logout() {
  await PushNotificationService.deactivateNotificationReception()
  await AuthenticationService.logout()
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
