<template>
  <VBanner class="border-0">
    <div class="max-width-1200 mx-auto w-full py-0 text-h5 sm:px-4">
      <slot name="default">
        <div class="flex justify-between max-sm:flex-col max-sm:gap-6">
          <div class="py-0 justify-center items-center">
            <slot name="text" />
          </div>
          <div class="flex flex-col items-center justify-center max-sm:items-start">
            <div class="flex items-center justify-end gap-2">
              <slot name="actions" />
            </div>
          </div>
        </div>
      </slot>
    </div>
  </VBanner>
</template>
