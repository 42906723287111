<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 14H4C3.71667 14 3.47917 13.9042 3.2875 13.7125C3.09583 13.5208 3 13.2833 3 13C3 12.7167 3.09583 12.4792 3.2875 12.2875C3.47917 12.0958 3.71667 12 4 12H10C10.2833 12 10.5208 12.0958 10.7125 12.2875C10.9042 12.4792 11 12.7167 11 13C11 13.2833 10.9042 13.5208 10.7125 13.7125C10.5208 13.9042 10.2833 14 10 14ZM14 6H4C3.71667 6 3.47917 5.90417 3.2875 5.7125C3.09583 5.52083 3 5.28333 3 5C3 4.71667 3.09583 4.47917 3.2875 4.2875C3.47917 4.09583 3.71667 4 4 4H14C14.2833 4 14.5208 4.09583 14.7125 4.2875C14.9042 4.47917 15 4.71667 15 5C15 5.28333 14.9042 5.52083 14.7125 5.7125C14.5208 5.90417 14.2833 6 14 6ZM11.1 10H4C3.71667 10 3.47917 9.90417 3.2875 9.7125C3.09583 9.52083 3 9.28333 3 9C3 8.71667 3.09583 8.47917 3.2875 8.2875C3.47917 8.09583 3.71667 8 4 8H12C11.7667 8.28333 11.5792 8.59167 11.4375 8.925C11.2958 9.25833 11.1833 9.61667 11.1 10Z"
      fill="currentColor"
    />
    <path
      d="M16 14C17.6575 14 19 12.6575 19 11C19 9.3425 17.6575 8 16 8C14.3425 8 13 9.3425 13 11C13 12.6575 14.3425 14 16 14ZM16 15.5C13.9975 15.5 10 16.505 10 18.5V20H22V18.5C22 16.505 18.0025 15.5 16 15.5Z"
      fill="currentColor"
    />
  </svg>
</template>
