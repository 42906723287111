import { defineStore } from 'pinia'
import { ref, type Ref } from 'vue'
import type { DepartmentDTO, HospitalDTO, WardDTO } from '@/generated'
import { useUserStore } from '@/stores/user'
import { FiltersService } from '@/services/telehealthApi/FiltersService'
import type { CaseWithVitalSigns } from '../case'

export interface VitalSignsFilter {
  sofaRange?: readonly (string | number)[]
  apacheRange?: readonly (string | number)[]
  temperatureRange?: readonly (string | number)[]
  laktateRange?: readonly (string | number)[]
  dialysis?: boolean
  ventilation?: boolean
  ecmo?: boolean
  impella?: boolean
  hospital?: HospitalDTO[]
  wards?: WardDTO[]
  departments?: DepartmentDTO[]
}

export const useVitalSignsFilterStore = defineStore('vitalSignsFilter', () => {
  const committedVitalSignsFilter: Ref<VitalSignsFilter> = ref({
    sofaRange: [],
    apacheRange: [],
    temperatureRange: [],
    laktateRange: [],
    dialysis: false,
    ventilation: false,
    ecmo: false,
    impella: false,
    hospital: [],
    departments: [],
    wards: []
  })

  const vitalSignsFilterInput: Ref<VitalSignsFilter> = ref({
    sofaRange: [],
    apacheRange: [],
    temperatureRange: [],
    laktateRange: [],
    dialysis: false,
    ventilation: false,
    ecmo: false,
    impella: false,
    hospital: [],
    departments: [],
    wards: []
  })

  function commitVitalSignsFilterInput() {
    committedVitalSignsFilter.value = { ...vitalSignsFilterInput.value }
  }

  async function initVitalSignsFilters() {
    const userStore = useUserStore()

    const response = await FiltersService.getVitalSignsFilters(userStore.user?.userName || '')

    if (response) {
      const filterData = response.vitalSigns as VitalSignsFilter[]
      if (filterData.length > 0) {
        const initialVitalSignsFilter = {
          sofaRange: filterData[0].sofaRange || [],
          apacheRange: filterData[0].apacheRange || [],
          temperatureRange: filterData[0].temperatureRange || [],
          laktateRange: filterData[0].laktateRange || [],
          dialysis: filterData[0].dialysis || false,
          ventilation: filterData[0].ventilation || false,
          ecmo: filterData[0].ecmo || false,
          impella: filterData[0].impella || false,
          hospital: filterData[0].hospital || [],
          wards: filterData[0].wards || [],
          departments: filterData[0].departments || []
        }

        committedVitalSignsFilter.value = initialVitalSignsFilter
        vitalSignsFilterInput.value = initialVitalSignsFilter
      }
    }
  }

  function filterVitalSignsCases(
    caze: CaseWithVitalSigns,
    useCommitedFilters: boolean = true
  ): boolean {
    return [
      filterByHospital,
      filterByWard,
      filterByDepartment,
      filterByVitalSigns,
      filterByOrganSupport
    ].every((filter) => filter(caze, useCommitedFilters))
  }

  function filterByHospital(caze: CaseWithVitalSigns, useCommitedFilters: boolean = true): boolean {
    const filter = useCommitedFilters ? committedVitalSignsFilter : vitalSignsFilterInput

    return (
      !filter.value.hospital?.length ||
      filter.value.hospital.some((h: HospitalDTO) => h.id === caze.hospital.id)
    )
  }

  function filterByWard(caze: CaseWithVitalSigns, useCommitedFilters: boolean = true): boolean {
    const filter = useCommitedFilters ? committedVitalSignsFilter : vitalSignsFilterInput
    return (
      !filter.value.wards?.length || filter.value.wards.some((w: WardDTO) => w.id === caze.ward.id)
    )
  }

  function filterByDepartment(
    caze: CaseWithVitalSigns,
    useCommitedFilters: boolean = true
  ): boolean {
    const filter = useCommitedFilters ? committedVitalSignsFilter : vitalSignsFilterInput
    return (
      !filter.value.departments?.length ||
      filter.value.departments.some((d: DepartmentDTO) => d.id === caze.department.id)
    )
  }

  function filterByVitalSigns(
    caze: CaseWithVitalSigns,
    useCommitedFilters: boolean = true
  ): boolean {
    const filter = useCommitedFilters ? committedVitalSignsFilter : vitalSignsFilterInput
    const vitalSigns = caze.vitalSigns

    if (!vitalSigns) return true

    const filters = [
      {
        range: filter.value.sofaRange,
        value:
          vitalSigns.find((vitalSign) => vitalSign.vitalSignType.typeKey === 'SOFA')?.items[0]
            ?.numericValue || 0
      },
      {
        range: filter.value.apacheRange,
        value:
          vitalSigns.find((vitalSign) => vitalSign.vitalSignType.typeKey === 'APACHE_II')?.items[0]
            ?.numericValue || 0
      },
      {
        range: filter.value.temperatureRange,
        value:
          vitalSigns.find((vitalSign) => vitalSign.vitalSignType.typeKey === 'TEMP')?.items[0]
            ?.numericValue || 0
      },
      {
        range: filter.value.laktateRange,
        value:
          vitalSigns.find((vitalSign) => vitalSign.vitalSignType.typeKey === 'LACTATE')?.items[0]
            ?.numericValue || 0
      }
    ]

    return filters.every(({ range, value }) => {
      if (!range || range.length !== 2 || typeof value !== 'number') return true
      return value >= Number(range[0]) && value <= Number(range[1])
    })
  }

  function filterByOrganSupport(
    caze: CaseWithVitalSigns,
    useCommitedFilters: boolean = true
  ): boolean {
    const filter = useCommitedFilters ? committedVitalSignsFilter : vitalSignsFilterInput
    const vitalSigns = caze.vitalSigns
    if (!vitalSigns) return true

    const filters = [
      {
        enabled: filter.value.dialysis,
        value: vitalSigns.find((vitalSign) => vitalSign.vitalSignType.typeKey === 'DIALYSIS')
          ?.items[0].numericValue
      },
      {
        enabled: filter.value.ventilation,
        value: vitalSigns.find((vitalSign) => vitalSign.vitalSignType.typeKey === 'VENTILATION')
          ?.items[0].numericValue
      },
      {
        enabled: filter.value.ecmo,
        value: vitalSigns.find((vitalSign) => vitalSign.vitalSignType.typeKey === 'ECMO')?.items[0]
          .numericValue
      },
      {
        enabled: filter.value.impella,
        value: vitalSigns.find((vitalSign) => vitalSign.vitalSignType.typeKey === 'IMP')?.items[0]
          .numericValue
      }
    ]

    return filters.every(({ enabled, value }) => {
      if (!enabled || typeof value !== 'boolean') return true
      return value === true
    })
  }

  return {
    vitalSignsFilterInput,
    committedVitalSignsFilter,
    commitVitalSignsFilterInput,
    initVitalSignsFilters,
    filterVitalSignsCases
  }
})
