import {
  SearchUsersFilterDTO,
  type LoginDTO,
  type LogoutDTO,
  type ShiftHandoverDTO,
  UserApi,
  type SearchUsersResponse
} from '@/generated'
import { globalApiConfig } from '@/services/telehealthApi/APIConfig'
import { urlSafeBase64 } from '@/util/base64'

export class UserService {
  static readonly userApi = new UserApi(globalApiConfig)

  static async getUsersForHandover(): Promise<SearchUsersResponse> {
    return (await UserService.userApi.searchUsers(SearchUsersFilterDTO.HANDOVER)).data
  }

  static async handoverShift(userName: string, handoverDTO?: ShiftHandoverDTO): Promise<void> {
    const encodedUserName = urlSafeBase64(userName)
    await UserService.userApi.handoverShift(encodedUserName, handoverDTO)
  }

  static async login(userName: string, loginDTO: LoginDTO): Promise<void> {
    const encodedUserName = urlSafeBase64(userName)
    await UserService.userApi.login(encodedUserName, loginDTO)
  }

  static async logoutDevice(userName: string, logoutDTO: LogoutDTO): Promise<void> {
    const encodedUserName = urlSafeBase64(userName)
    await UserService.userApi.logoutDevice(encodedUserName, logoutDTO)
  }
}
