<template>
  <v-navigation-drawer
    v-model="isMobileMenuDrawerOpened"
    location="right"
    :class="{ '!w-[100vw]': isMobileMenuDrawerOpened }"
  >
    <div class="flex flex-col h-full">
      <VRow class="m-4 flex-grow-0">
        <PatientSearch />
      </VRow>

      <VRow class="m-2 flex-grow-0">
        <VBtn
          :to="{ name: routeNames.CONSULTATIONS }"
          :active="false"
          class="button-large p-2 w-full justify-start"
          variant="text"
          :class="{
            'text-primary bg-[#F5F5F7]': isConsultationsActive,
            'text-secondary-text': !isConsultationsActive
          }"
        >
          <template #prepend>
            <VIcon icon="calendar_month" class="text-mobile-menu-icon opacity-55" />
          </template>
          <span class="text-h6 ml-2">
            {{ t('Consultations') }}
          </span>
        </VBtn>
      </VRow>

      <VRow class="m-2 flex-grow-0">
        <VBtn
          :to="{ name: routeNames.VITAL_DATA }"
          :active="false"
          class="button-large p-2 w-full justify-start"
          :class="{
            'text-primary bg-[#F5F5F7]': isVitalDataActive,
            'text-secondary-text': !isVitalDataActive
          }"
          variant="text"
        >
          <template #prepend>
            <VIcon icon="custom:patientList" class="text-mobile-menu-icon opacity-55" />
          </template>
          <span class="text-h6 ml-2">
            {{ t('Vital data') }}
          </span>
        </VBtn>
      </VRow>

      <DividerLine />

      <VRow class="m-2 flex-grow-0">
        <VBtn
          :to="{ name: routeNames.CREATE_CONSULTATION }"
          :active="false"
          :disabled="hasGodModeShift"
          class="button-large p-2 w-full justify-start"
          variant="text"
        >
          <template #prepend>
            <VIcon icon="add" class="text-mobile-menu-icon opacity-55" />
          </template>
          <span class="text-h6 text-secondary-text ml-2">
            {{ t('Create consultation') }}
          </span>
        </VBtn>
      </VRow>

      <VRow class="m-2 flex-grow-0">
        <VBtn
          :active="false"
          class="button-large p-2 w-full justify-start"
          color="secondary"
          :disabled="disableEmergencyButton"
          variant="text"
          @click="handleOpenEmergencyDialog"
        >
          <template #prepend>
            <VIcon icon="emergency" class="material-icons-round" />
          </template>
          <span class="ml-2">
            {{ t('Emergency') }}
          </span>
        </VBtn>
      </VRow>

      <DividerLine />

      <VRow class="m-2 flex-grow-0">
        <VBtn
          :to="{ name: routeNames.USER_PROFILE }"
          :active="false"
          class="button-large p-2 w-full justify-start"
          variant="text"
        >
          <template #prepend>
            <VIcon icon="person" class="text-mobile-menu-icon opacity-55" />
          </template>
          <span class="text-h6 text-secondary-text ml-2">
            {{ t('Account') }}
          </span>
        </VBtn>
      </VRow>

      <VSpacer />
      <VRow class="m-4 flex-grow-0 flex flex-col gap-4">
        <div class="flex flex-col">
          <span class="text-caption text-secondary-text">{{ t('Logged in as') }}</span>
          <span class="text-body-1 text-primary-text">{{ fullUserName }}</span>
        </div>
        <VBtn :to="{ name: routeNames.LOGOUT }" color="primary" class="button-large w-full !h-11">
          {{ t('Logout') }}
        </VBtn>
      </VRow>

      <CreateEmergencyConsultationDialog v-model="showCreateEmergencyConsultationDialog" />
    </div>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
import { routeNames } from '@/router/routes'
import PatientSearch from '@/components/navigation/patientsearch/PatientSearch.vue'
import { useI18n } from 'vue-i18n'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'
import { useCaseStore } from '@/stores/case'
import CreateEmergencyConsultationDialog from '@/components/navigation/CreateEmergencyConsultationDialog.vue'
import DividerLine from '@/atoms/DividerLine.vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const { t } = useI18n()
const { caseList } = storeToRefs(useCaseStore())

const showCreateEmergencyConsultationDialog = ref<boolean>(false)

const isMobileMenuDrawerOpened = defineModel<boolean>()

const { hasGodModeShift } = storeToRefs(useUserStore())

const disableEmergencyButton = computed<boolean>(
  () => caseList.value.every((c) => c.emergencyUnavailable) || hasGodModeShift.value
)

const fullUserName = computed(() => {
  const title = useUserStore().user?.title || ''
  const firstName = useUserStore().user?.firstName || ''
  const lastName = useUserStore().user?.lastName || ''

  return `${title} ${firstName} ${lastName}`
})
const isVitalDataActive = computed(() => router.currentRoute.value.name === routeNames.VITAL_DATA)
const isConsultationsActive = computed(
  () => router.currentRoute.value.name === routeNames.CONSULTATIONS
)

function handleOpenEmergencyDialog() {
  isMobileMenuDrawerOpened.value = false
  openEmergencyDialog()
}

function openEmergencyDialog() {
  showCreateEmergencyConsultationDialog.value = true
}

function handleWindowWidthChange() {
  if (window.innerWidth >= 1024) {
    isMobileMenuDrawerOpened.value = false
  }
}

onMounted(() => {
  window.addEventListener('resize', handleWindowWidthChange)
})

onUnmounted(() => {
  window.removeEventListener('resize', handleWindowWidthChange)
})
</script>

<style scoped></style>
